import React from 'react'

interface IconProps {
  size?: 'medium' | 'small'
  stroke?: string
}

export const CircuitBoardIcon: React.FC<IconProps> = ({ size = 'medium', stroke= 'black' }) => {
  const getSize = () => {
    switch (size) {
    case 'medium': return 24
    case 'small': return 20
    }
  }

  const calculatedSize = getSize()

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={calculatedSize}
      height={calculatedSize}
      viewBox='0 0 24 24'
      fill='none'
      stroke={stroke}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-circuit-board'
    >
      <rect width='18' height='18' x='3' y='3' rx='2' />
      <path d='M11 9h4a2 2 0 0 0 2-2V3' />
      <circle cx='9' cy='9' r='2' />
      <path d='M7 21v-4a2 2 0 0 1 2-2h4' />
      <circle cx='15' cy='15' r='2' />
    </svg>
  )
}
