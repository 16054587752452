import React, { useCallback } from 'react'
import { IconButton, Tooltip } from '@mui/material'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import trafficSettingsModalOpenAtom from '../../../recoil/trafficSettingsModalOpen/atom'

import variables from '../../../variables.module.scss'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import packetCapturingStoppedAtom from '../../../recoil/packetCapturingStopped'
import { appStopTrafficCapturingDisabled } from '../../../types/global'
import Button from '@mui/material/Button'

interface TrafficSettingsButtonProps {
  iconOnly?: boolean
}

export const TrafficSettingsButton: React.FC<TrafficSettingsButtonProps> = ({ iconOnly = true }) => {
  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)
  const setTrafficSettingsModalOpen = useSetRecoilState(trafficSettingsModalOpenAtom)

  const handleOpenSettingsModal = useCallback(() => {
    if (!appStopTrafficCapturingDisabled() && capturingStopped === null) {
      return
    }

    setTrafficSettingsModalOpen(true)
  }, [capturingStopped])

  return (
    <Tooltip title='Manage traffic settings' placement='top' arrow>
      {iconOnly ? (<IconButton size='small' sx={{
        width: '26px',
        height: '26px',
        p: '2px',
        mt: '2px',
        ml: '10px',
        borderRadius: '6px',
        backgroundColor: variables.lightestSlateColor
      }} onClick={handleOpenSettingsModal}>
        <SettingsRoundedIcon htmlColor={variables.blueColor} sx={{ fontSize: '18px' }} />
      </IconButton>) : (
        <Button
          variant='contained'
          className={`themeButton slate`}
          size='small'
          startIcon={
            <SettingsRoundedIcon htmlColor={variables.headerBackgroundColor} sx={{ mr: '5px' }} />
          }
          onClick={handleOpenSettingsModal}
        >
          Settings
        </Button>
      )}
    </Tooltip>
  )
}
