import React, { useEffect, useRef, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography
} from '@mui/material'

import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import styles from './SettingSection.module.sass'
import variables from '../../../../variables.module.scss'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface SettingSectionProps {
  icon: React.ReactNode
  title: string
  description: string
  helpLink?: string
  frozen?: boolean
  defaultExpanded?: boolean
  scrollIntoView?: boolean
  children: React.ReactNode | React.ReactNode[]
}

export const SettingSection: React.FC<SettingSectionProps> = ({
  icon,
  title,
  description,
  helpLink,
  frozen = false,
  defaultExpanded = false,
  scrollIntoView = false,
  children
}) => {
  const [hovered, setHovered] = useState(false)
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  const accordionBoxRef = useRef(null)
  const scrollTimeout = useRef<NodeJS.Timeout>(null)

  useEffect(() => {
    if (scrollIntoView && accordionBoxRef.current) {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }

      scrollTimeout.current = setTimeout(() => {
        accordionBoxRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end'
        })
      }, 500)
    }

    return () => clearTimeout(scrollTimeout.current)
  }, [scrollIntoView])

  return (
    <Box pb='20px' boxSizing='border-box' width='100%' ref={accordionBoxRef}>
      <Accordion
        disabled={frozen}
        className={styles.SettingSection}
        expanded={expanded}
        sx={!frozen ? {
          backgroundColor: expanded || hovered ?
            `${variables.headerBackgroundColor} !important` :
            variables.dataBackgroundColor,
          transform: expanded || hovered ? 'scale(1.01)' : null,
          borderColor: expanded || hovered ? `${variables.lightBlueColor} !important` : null,
        } : {
          backgroundColor: `${variables.headerBackgroundColor} !important`
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onChange={(_, expanded) => setExpanded(expanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display='flex' alignItems='center' gap='15px' width='100%'>
            {icon}
            <Typography
              display='block'
              variant='h2'
              fontFamily={variables.textFontFamily}
              fontWeight={600}
              fontSize='16px'
              color={variables.fontColor}
              flex={1}
            >
              {title}
            </Typography>
            {typeof helpLink === 'string' && (
              <Tooltip title={`Learn more about ${title}`} placement='top-end' arrow>
                <Link href={helpLink} target='_blank'>
                  <IconButton size='small'>
                    <HelpOutlineRoundedIcon fontSize='small' htmlColor={variables.lightGrayColor} />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb='15px'>
            <Typography
              variant='body2'
              fontFamily={variables.textFontFamily}
              fontWeight={400}
              color={variables.grayFontColor}
            >
              {description}
            </Typography>
          </Box>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
