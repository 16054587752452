import { toast } from 'react-toastify'
import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { HubBaseUrl } from '../consts'
import { appSentryEnabled } from '../types/global'

interface GetSentryDsnResponse {
  dsn: string
}

const getSentryDsn = (): Promise<GetSentryDsnResponse> => {
  return fetch(`${HubBaseUrl}/sentry/front`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Kubeshark-Capture': 'ignore',
    },
    body: JSON.stringify({
      version: '0.0.1'
    })
  })
    .then((response) =>
      response.ok
        ? response
        : response.text().then((err) => Promise.reject(err))
    )
    .then((response) => response.json())
}

const initSentry = (dsn: string) => {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const useSentryTracking = () => {
  useEffect(() => {
    if (!appSentryEnabled()) {
      return
    }

    getSentryDsn()
      .then(response => initSentry(response.dsn))
      .catch(err => {
        let msg = 'Could not initialize Sentry'
        if (err.length > 0) {
          msg += ` ${err.toString()}`
        }

        console.error(msg)
        toast.error(msg, {
          theme: 'colored'
        })
      })
  }, [])
}

export default useSentryTracking
