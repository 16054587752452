import { atom } from "recoil"

interface SettingsModalOpenOptions {
  open: boolean,
  settingId?: string
}

const trafficSettingsModalOpenAtom = atom<boolean | SettingsModalOpenOptions>({
  key: "trafficSettingsModalOpenAtom",
  default: false
})

export default trafficSettingsModalOpenAtom;
