import React, { useEffect, useState } from 'react'

import enabledDissectorsAtom from '../../../../../../recoil/enabledDissectors'
import { useRecoilState } from 'recoil'

import { EnabledDissectors } from '../../../../../../types/trafficSettings'

import { Box, FormControlLabel, Grid, Typography } from '@mui/material'
import { StyledSwitch } from '../../../../../UI/StyledSwitch/StyledSwitch'

import { HubBaseUrl } from '../../../../../../consts'
import variables from '../../../../../../variables.module.scss'
import { toast } from 'react-toastify'

interface DissectorSwitchProps {
  name: string
  isChecked: boolean
  onChange: (checked: boolean) => void
}

const DissectorSwitch: React.FC<DissectorSwitchProps> = ({ name, isChecked = false, onChange }) => {
  const [checked, setChecked] = useState(isChecked)

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const handleChange = (event) => {
    setChecked(event.target.checked)
    onChange(event.target.checked)
  }

  return (
    <FormControlLabel
      control={
        <StyledSwitch
          checked={checked}
          onChange={handleChange}
        />
      }
      labelPlacement="start"
      label={
        <Typography
          variant="body2"
          fontFamily={variables.textFontFamily}
          fontWeight={600}
          textAlign="left"
          lineHeight={1}
          color={variables.fontColor}
          width="100%"
        >
          {name.toUpperCase()}
        </Typography>
      }
      sx={{ width: '100%' }}
    />
  )
}

export const EnabledDissectorsView: React.FC = () => {
  const [enabledDissectors, setEnabledDissectors] = useRecoilState<EnabledDissectors>(enabledDissectorsAtom)
  const [dissectorsEntries, setDissectorsEntries] = useState([])

  const postEnabledDissectors = (enabledDissectors: string[]) => {
    fetch(
      `${HubBaseUrl}/settings/enabled-dissectors`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Kubeshark-Capture': 'ignore'
        },
        body: JSON.stringify({ enabledDissectors })
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .catch((err) => {
        console.error(err)
        toast.error(`Failed to set enabled dissectors`, {
          theme: 'colored'
        })
      })
  }

  useEffect(() => {
    setDissectorsEntries(Object.entries(enabledDissectors))
  }, [enabledDissectors])

  return (
    <Box boxSizing='border-box' width='100%' height='100%'>
      <Typography
        variant='body1'
        fontFamily={variables.textFontFamily}
        fontWeight={500}
        fontSize='16px'
        color={variables.secondaryFontColor}
        mb='20px'
      >
        TCP and DNS protocol dissectors can consume significant CPU and memory resources. We suggest enabling them only on demand.
      </Typography>
      <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {dissectorsEntries.map(([dissector, state]) => {
          return (
            <Grid item xs={4} sm={4} md={4} key={dissector}>
              <Box
                p="8px"
                pr="24px"
                borderRadius="6px"
                border={`1px solid ${variables.lightestSlateColor}`}
                bgcolor={variables.dataBackgroundColor}
              >
                <DissectorSwitch
                  name={dissector}
                  isChecked={state}
                  onChange={(checked) => {
                    setEnabledDissectors(prevState => {
                      const newState = { ...prevState }
                      newState[dissector] = checked

                      const dissectors = Object.keys(newState).filter((dissector) => {
                        if (newState[dissector]) {
                          return dissector
                        }
                      })

                      postEnabledDissectors(dissectors)
                      return newState
                    })
                  }}
                />
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
