import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import variables from '../../../variables.module.scss';

const Root = styled(Box)<{ leftAligned?: boolean }>(({ leftAligned }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: 40,
  paddingBottom: 8,
  textAlign: leftAligned ? 'left' : 'center', // Default to center if not leftAligned
}));
const Tab = styled(Box)(() => ({
  display: 'inline-flex',
  textTransform: 'uppercase',
  color: variables.blueColor,
  cursor: 'pointer',
  alignItems: 'center',
  paddingBottom: 3,
}));

const ActiveTab = styled(Box)(({ theme }: { theme: Theme }) => ({
  fontWeight: 700,
  textTransform: 'uppercase',
  color: variables.fontColor,
  cursor: 'unset',
  borderBottom: `2px solid ${variables.fontColor}`,
  paddingBottom: 3,

  '&.dark': {
    color: theme.palette.common.black,
    borderBottom: `2px solid ${theme.palette.common.black}`,
  },
}));

const DisabledTab = styled(Box)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.grey[400],
  textTransform: 'uppercase',
  cursor: 'unset',
}));

const HighlightTab = styled(Box)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.light,
}));

const Separator = styled(Box)(({ theme }: { theme: Theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.dark}`,
  height: 20,
  verticalAlign: 'middle',
  margin: '0 20px',
  cursor: 'unset',
}));

interface Props {
  classes?: Record<string, string>;
  tabs: Array<{ tab: string; disabled?: boolean; disabledMessage?: string; highlight?: boolean; badge?: React.ReactNode }>;
  currentTab: string;
  color?: string;
  onChange: (tab: string) => void;
  leftAligned?: boolean;
  dark?: boolean;
}

const Tabs: React.FC<Props> = ({ tabs, currentTab, color, onChange, leftAligned, dark }) => {
  return (
    <Root leftAligned={leftAligned}>
      {tabs.map(({ tab, disabled, disabledMessage, highlight, badge }, index) => {
        const active = currentTab === tab;
        const TabComponent = active ? ActiveTab : Tab;
        const FinalTab = disabled ? DisabledTab : highlight ? HighlightTab : TabComponent;

        const tabLink = (
          <FinalTab
            data-cy={`tab-${tab}`}
            className={dark && active ? 'dark' : ''}
            onClick={() => !disabled && onChange(tab)}
            sx={!disabled ? { color } : {}}
          >
            {tab}
            {React.isValidElement(badge) && badge}
          </FinalTab>
        );

        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={tab}>
            {disabled && disabledMessage ? (
              <Tooltip title={disabledMessage}>{tabLink}</Tooltip>
            ) : (
              tabLink
            )}
            {index < tabs.length - 1 && <Separator key={`${tab}_separator`} />}
          </Box>
        );
      })}
    </Root>
  );
};

export default Tabs;
