import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import queryAtom from '../recoil/query/atom'
import queryBuildAtom from '../recoil/queryBuild/atom'
import queryBackgroundColorAtom from '../recoil/queryBackgroundColor/atom'
import { ColorYellow, LocalStorageKey } from '../consts'
import { useEffect } from 'react'

const DEFAULT_QUERY = window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_FILTER ? window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_FILTER.trim() : "" ;

const useQueryRestore = (): void => {
  const setQuery = useSetRecoilState(queryAtom)
  const setQueryBuild = useSetRecoilState(queryBuildAtom)
  const setQueryBackgroundColor = useSetRecoilState(queryBackgroundColorAtom)

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const shouldRestoreQuery = localStorage.getItem(
      LocalStorageKey.ShouldRestoreQuery
    )
    localStorage.removeItem(LocalStorageKey.ShouldRestoreQuery)

    const querySearchParam = searchParams.get('q')
    searchParams.delete('q')

    const resolvedQuery = shouldRestoreQuery || querySearchParam || DEFAULT_QUERY
    searchParams.append('q', resolvedQuery)

    if (resolvedQuery.length > 0) {
      setQueryBuild(resolvedQuery)
      setQuery(resolvedQuery)
      setQueryBackgroundColor(ColorYellow)
      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      })
    }
  }, [])
}

export default useQueryRestore
