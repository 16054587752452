import React, { useEffect } from 'react'
import { Alert, Grid } from '@mui/material'
import variables from '../../../../../../../variables.module.scss'
import { PodTargetSetting } from '../../../../../../UI/StatusBar/PodTargetSetting/PodTargetSetting'
import PasswordIcon from '@mui/icons-material/Password'
import DataArrayIcon from '@mui/icons-material/DataArray'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { appBpfOverrideDisabled } from '../../../../../../../types/global'
import { SettingDisabledCard } from '../../../../../../UI/StatusBar/PodTargetSetting/SettingDisabledCard'
import { InlineCode } from '../../../../../../UI/InlineCode/InlineCode'
import MemoryIcon from '@mui/icons-material/Memory'
import { useRecoilState, useRecoilValue } from 'recoil'
import appliedPodRegexAtom from '../../../../../../../recoil/appliedPodRegex'
import appliedPodNamespacesAtom from '../../../../../../../recoil/appliedPodNamespaces'
import appliedPodExcludedNamespacesAtom from '../../../../../../../recoil/appliedPodExcludedNamespaces'
import appliedPodBpfOverrideAtom from '../../../../../../../recoil/appliedPodBpfOverride'
import trafficPlaybackAtom from '../../../../../../../recoil/trafficPlayback'
import { useAuth } from '../../../../../../UI/Auth/Auth'
import { TRAFFIC_PLAYBACK_OK } from '../../../../../../../recoil/trafficPlayback/atom'
import useTargeting from '../../../../../../../hooks/useTargeting'

export const PodTargetingSettings = () => {
  const [podRegex, setPodRegex] = useRecoilState(appliedPodRegexAtom)
  const [podNamespaces, setPodNamespaces] = useRecoilState(appliedPodNamespacesAtom)
  const [podExcludedNamespaces, setPodExcludedNamespaces] = useRecoilState(appliedPodExcludedNamespacesAtom)
  const [podBpfOverride, setPodBpfOverride] = useRecoilState(appliedPodBpfOverrideAtom)

  const [podRegexChanging, setPodRegexChanging] = React.useState(false)
  const [podNamespacesChanging, setPodNamespacesChanging] = React.useState(false)
  const [podExcludedNamespacesChanging, setPodExcludedNamespacesChanging] = React.useState(false)
  const [podBpfOverrideChanging, setPodBpfOverrideChanging] = React.useState(false)

  const [podRegexActive, setPodRegexActive] = React.useState(false)
  const [podNamespacesActive, setPodNamespacesActive] = React.useState(false)
  const [podExcludedNamespacesActive, setPodExcludedNamespacesActive] = React.useState(false)
  const [podBpfOverrideActive, setPodBpfOverrideActive] = React.useState(false)

  const [podRegexError, setPodRegexError] = React.useState(false)
  const [podNamespacesError, setPodNamespacesError] = React.useState(false)
  const [podExcludedNamespacesError, setPodExcludedNamespacesError] = React.useState(false)
  const [podBpfOverrideError, setPodBpfOverrideError] = React.useState(false)

  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const { postTargetSetting, fetchTargetSettings } = useTargeting()

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchTargetSettings()
  }, [trafficPlayback, shouldAuthenticate])

  useEffect(() => setPodRegexActive(podRegex.length > 0), [podRegex])
  useEffect(() => setPodNamespacesActive(podNamespaces.length > 0), [podNamespaces])
  useEffect(() => setPodExcludedNamespacesActive(podExcludedNamespaces.length > 0), [podExcludedNamespaces])
  useEffect(() => setPodBpfOverrideActive(podBpfOverride.length > 0), [podBpfOverride])

  const handlePodRegexChange = (regex: string) => {
    setPodRegex(regex)
  }

  const handlePodNamespacesChange = (namespacesStr: string) => {
    const namespaces = namespacesStr.trim().split(/\s*,\s*/)
    setPodNamespaces(namespaces)
  }

  const handlePodExcludedNamespacesChange = (namespacesStr: string) => {
    const excludedNamespaces = namespacesStr.trim().split(/\s*,\s*/)
    setPodExcludedNamespaces(excludedNamespaces)
  }

  const handleBpfOverrideChange = (bpf: string) => {
    setPodBpfOverride(bpf)
  }

  const postPodRegex = () => {
    postTargetSetting(
      'regex',
      podRegex.trim(),
      JSON.stringify({regex: podRegex.trim()}),
      setPodRegexError,
      setPodRegexActive,
      setPodRegex,
      setPodRegexChanging,
      `Invalid pod regex! Check syntax & retry.`
    )
  }

  const postPodNamespaces = () => {
    const namespaces = podNamespaces.filter(value => value.trim())

    postTargetSetting(
      'namespaces',
      namespaces,
      JSON.stringify({namespaces}),
      setPodNamespacesError,
      setPodNamespacesActive,
      setPodNamespaces,
      setPodNamespacesChanging,
      `Invalid pod namespaces!`
    )
  }

  const postPodExcludedNamespaces = () => {
    const excludedNamespaces = podExcludedNamespaces.filter(value => value.trim())

    postTargetSetting(
      'excluded-namespaces',
      excludedNamespaces,
      JSON.stringify({ namespaces: excludedNamespaces }),
      setPodExcludedNamespacesError,
      setPodExcludedNamespacesActive,
      setPodExcludedNamespaces,
      setPodExcludedNamespacesChanging,
      `Invalid excluded pod namespaces!`
    )
  }

  const postBpfOverride = () => {
    postTargetSetting(
      'bpf',
      podBpfOverride.trim(),
      JSON.stringify({bpf: podBpfOverride.trim()}),
      setPodBpfOverrideError,
      setPodBpfOverrideActive,
      setPodBpfOverride,
      setPodBpfOverrideChanging,
      `Invalid pod regex! Check syntax & retry.`
    )
  }

  return (
    <>
      <Alert
        variant='outlined'
        severity='info'
        icon={<InfoOutlinedIcon fontSize='small' htmlColor={variables.blueColor} />}
        sx={{
          mb: '15px',
          width: '100%',
          boxSizing: 'border-box',
          backgroundColor: variables.dataBackgroundColor,
          borderColor: variables.lightestSlateColor,
          color: variables.grayColor
        }}>
        <strong style={{ color: variables.fontColor }}>Notice: </strong>
            Applied targeting rules are cluster-wide
      </Alert>
      <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <PodTargetSetting
          icon={<PasswordIcon htmlColor={variables.blueColor} />}
          title='By regex'
          placeholder='Pod name regex'
          handleValueChange={handlePodRegexChange}
          value={podRegex}
          changing={podRegexChanging}
          setChanging={setPodRegexChanging}
          active={podRegexActive}
          error={podRegexError}
          postValue={postPodRegex}
        />
        <>
          {appBpfOverrideDisabled() && (
            <Grid item xs={4} sm={4} md={4}>
              <SettingDisabledCard>
                <span
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    fontFamily: variables.textFontFamily,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: 2,
                    color: variables.fontColor
                  }}
                >
                      Set&nbsp;<InlineCode>tap.packetCapture</InlineCode>&nbsp;
                      to&nbsp;<InlineCode>af_packet</InlineCode>
                      &nbsp;to use BPF override
                </span>
              </SettingDisabledCard>
            </Grid>
          )}
          {!appBpfOverrideDisabled() && <PodTargetSetting
            icon={<MemoryIcon htmlColor={variables.blueColor} />}
            title='By BPF override'
            placeholder='Enter BPF string'
            handleValueChange={handleBpfOverrideChange}
            value={podBpfOverride}
            changing={podBpfOverrideChanging}
            setChanging={setPodBpfOverrideChanging}
            active={podBpfOverrideActive}
            error={podBpfOverrideError}
            postValue={postBpfOverride}
          />}
        </>
        <PodTargetSetting
          icon={<DataArrayIcon htmlColor={variables.blueColor} />}
          title='By namespaces'
          placeholder='namespace1,namespace2'
          handleValueChange={handlePodNamespacesChange}
          value={podNamespaces.filter(value => value.trim()).length === 0 ? '' : podNamespaces}
          changing={podNamespacesChanging}
          setChanging={setPodNamespacesChanging}
          active={podNamespacesActive}
          error={podNamespacesError}
          postValue={postPodNamespaces}
        />
        <PodTargetSetting
          icon={<DataArrayIcon htmlColor={variables.blueColor} />}
          title='By excluded namespaces'
          placeholder='namespace1,namespace2'
          handleValueChange={handlePodExcludedNamespacesChange}
          value={podExcludedNamespaces.filter(value => value.trim()).length === 0 ? '' : podExcludedNamespaces}
          changing={podExcludedNamespacesChanging}
          setChanging={setPodExcludedNamespacesChanging}
          active={podExcludedNamespacesActive}
          error={podExcludedNamespacesError}
          postValue={postPodExcludedNamespaces}
        />
      </Grid>
    </>
  )
}
