import style from './StatusBar.module.sass';
import React, { useEffect, useState } from "react";
import Queryable from "../Queryable/Queryable";
import variables from '../../../variables.module.scss'

import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from '../../../recoil/trafficPlayback/atom'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Box, ButtonBase, Fade } from '@mui/material'

import { appTargetedPodsUpdateDisabled, checkTargetedPodsUpdateDisabled } from '../../../types/global'
import { useAuth } from '../Auth/Auth';
import targetedNodesAtom from '../../../recoil/targetedNodes';
import { Node } from '../../../types/resources';

import targetedPodsAtom from '../../../recoil/targetedPods'
import useTargeting from '../../../hooks/useTargeting'
import useElementRouter from '../../../hooks/useElementRouter'
import { authorizeAction, AUTHZ_ACTIONS } from '../Auth/SamlAuth/Authorization'
import Tooltip from '@mui/material/Tooltip'

const pluralize = (noun: string, amount: number) => {
  return `${noun}${amount !== 1 ? 's' : ''}`
}

const uniqueNamespaces = (targets: Target[]) => {
  return [...new Set(targets.map(pod => `[${pod.namespace}]`))];
}

const uniqueNodes = (targets: Target[]) => {
  const uniqueNodeStrings = [...new Set(targets.map((pod): string => JSON.stringify(pod.node)))]
  return uniqueNodeStrings.map((nodeStr): Node => JSON.parse(nodeStr))
}

export interface Target {
  name: string;
  namespace: string;
  node: Node;
  uid: string;
}

interface StatusBarContentProps {
  expandedBar: boolean;
  setExpandedBar: (v: boolean) => void;
}

const StatusBarContent: React.FC<StatusBarContentProps> = ({
  expandedBar,
  setExpandedBar,
}) => {
  const targets = useRecoilValue(targetedPodsAtom)

  const [targetedNodes, setTargetedNodes] = useRecoilState(targetedNodesAtom)

  useEffect(() => {
    setTargetedNodes(uniqueNodes(targets))
  }, [targets])

  useEffect(() => {
    if (!expandedBar) {
      return
    }

    if (!checkTargetedPodsUpdateDisabled()) {
      setExpandedBar(expandedBar)
    }
  }, [expandedBar])

  const { navigateToElement } = useElementRouter()

  return (
    <div className={style.statusBarContainer} onMouseOver={() => setExpandedBar(true)} onMouseLeave={() => setExpandedBar(false)}>
      <div className={`${style.statusBar} ${(expandedBar && targets.length > 0 ? `${style.expandedStatusBar}` : "")}`} data-cy="expandedStatusBar">
        <div className={style.podsCount}>
          {targets.length > 0 && (
            <span className={style.podsCountText} data-cy="podsCountText">
              {`
                Targeting ${targets.length} ${pluralize('pod', targets.length)}
                in ${uniqueNamespaces(targets).length} ${pluralize('namespace', uniqueNamespaces(targets).length)}
                across ${targetedNodes.length} ${pluralize('node', targetedNodes.length)}
              `}
            </span>
          )}
          {targets.length === 0 && <span className={style.podsCountText}>
            No pods to target by current regex/namespaces
          </span>}
          {!appTargetedPodsUpdateDisabled() && (
            <Tooltip arrow title={authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS) ? 'Edit targeting settings' : 'You are unauthorized to edit targeting settings'}>
              <ButtonBase onClick={() => {
                if (authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS)) {
                  navigateToElement('settings:targeting')
                }
              }}>
                <span style={{
                  fontFamily: variables.textFontFamily,
                  fontWeight: 700,
                  whiteSpace: 'nowrap',
                  color: variables.dataBackgroundColor,
                  textTransform: 'lowercase',
                  textDecoration: 'underline',
                  textDecorationColor: variables.dataBackgroundColor,
                  opacity: authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS) ? 1 : 0.5
                }}>
                  [edit]
                </span>
              </ButtonBase>
            </Tooltip>
          )}
        </div>
        {expandedBar && targets.length > 0 && <div className={style.tableContainer}>
          <table>
            <thead>
              <tr>
                <th style={{ width: "70%" }}>Pod name</th>
                <th style={{ width: "30%" }}>Namespace</th>
              </tr>
            </thead>
            <tbody>
              {targets.map(pod => <tr key={pod.uid}>
                <td style={{ position: "relative", width: "70%" }}>
                  <Queryable
                    query={`src.pod.metadata.name == "${pod.name}" or dst.pod.metadata.name == "${pod.name}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {pod.name}
                  </Queryable>
                </td>
                <td style={{ position: "relative", width: "30%" }}>
                  <Queryable
                    query={`src.namespace == "${pod.namespace}" or dst.namespace == "${pod.namespace}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {pod.namespace}
                  </Queryable>
                </td>
              </tr>)}
            </tbody>
          </table>
          <hr />
          <table>
            <thead>
              <tr>
                <th style={{ width: "70%" }}>Node name</th>
                <th style={{ width: "30%" }}>Node IP</th>
              </tr>
            </thead>
            <tbody>
              {targetedNodes.map(node => <tr key={node.name}>
                <td style={{ position: "relative", width: "70%" }}>
                  <Queryable
                    query={`node.name == "${node.name}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {node.name}
                  </Queryable>
                </td>
                <td style={{ position: "relative", width: "30%" }}>
                  <Queryable
                    query={`node.ip == "${node.ip}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {node.ip}
                  </Queryable>
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>}
      </div>
    </div>
  )
}

export const StatusBar: React.FC = () => {
  const [expandedBar, setExpandedBar] = useState(false);
  const [targetedPodsFetched, setTargetedPodsFetched] = useState(false)
  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const { fetchTargetedPods: fetchTargets, fetchTargetSettings } = useTargeting()

  const fetchTargetedPods = () => {
    fetchTargets().finally(() => setTargetedPodsFetched(true));
  }

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchTargetSettings()
    fetchTargetedPods()

    const fetchInterval = setInterval(() => {
      if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
        return
      }

      if (shouldAuthenticate) {
        return
      }

      fetchTargetedPods()
    }, 5000)

    return () => clearInterval(fetchInterval)
  }, [trafficPlayback, shouldAuthenticate])

  return (
    <Fade in={targetedPodsFetched}>
      <Box>
        <StatusBarContent
          expandedBar={expandedBar}
          setExpandedBar={setExpandedBar}
        />
      </Box>
    </Fade>
  );
}
