import { useRecoilState, useSetRecoilState } from 'recoil'
import packetCapturingStoppedAtom from '../recoil/packetCapturingStopped'
import { HubBaseUrl } from '../consts'
import { toast } from 'react-toastify'
import { EnabledDissectors } from '../types/trafficSettings'
import enabledDissectorsAtom from '../recoil/enabledDissectors'
import trafficSettingsModalOpenAtom from '../recoil/trafficSettingsModalOpen'

interface TrafficCapturingSettingsResponse {
  stopped: boolean
  enabledDissectors: string[]
}

export const useTrafficCapturingStatus = () => {
  const setCapturingStopped = useSetRecoilState(packetCapturingStoppedAtom)
  const setTrafficSettingsModalOpen = useSetRecoilState(trafficSettingsModalOpenAtom)
  const [enabledDissectors, setEnabledDissectors] = useRecoilState<EnabledDissectors>(enabledDissectorsAtom)

  const getCapturingStatus = () => {
    let status: number

    fetch(
      `${HubBaseUrl}/settings/status`,
      {
        method: 'GET',
        headers: {
          'X-Kubeshark-Capture': 'ignore',
        },
      },
    )
      .then((response) => {
        status = response.status
        return response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      })
      .then((response) => response.json())
      .then((responseBody: TrafficCapturingSettingsResponse) => {
        const stopped = typeof responseBody.stopped === 'boolean' && responseBody.stopped ||
          typeof responseBody.stopped === 'string' && responseBody.stopped === 'true'

        setCapturingStopped(stopped)
        if (stopped) {
          setTrafficSettingsModalOpen(true)
        }

        const dissectors: EnabledDissectors = {}

        Object.keys(enabledDissectors).map(dissector => {
          dissectors[dissector] = responseBody.enabledDissectors.includes(dissector)
        })

        setEnabledDissectors(dissectors)
      })
      .catch((err) => {
        if (err.length > 0) {
          console.error(err)
          if (status > 400 && ![401, 502, 504].includes(status)) {
            toast.error(err.toString(), {
              theme: 'colored'
            })
          }
        }
      })
  }

  const postCapturingStoppedStatus = (state: boolean) => {
    fetch(`${HubBaseUrl}/settings/stopped`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Kubeshark-Capture': 'ignore',
      },
      body: JSON.stringify({ state })
    })
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then((response) => response.json())
      .then((responseBody: TrafficCapturingSettingsResponse) => {
        setCapturingStopped(
          typeof responseBody.stopped === 'boolean' && responseBody.stopped ||
          typeof responseBody.stopped === 'string' && responseBody.stopped === 'true'
        )
      })
      .catch((err) => {
        if (err.length > 0) {
          console.error(err)
          toast.error(err.toString(), {
            theme: 'colored'
          })
        }
      })
  }

  return {
    getCapturingStatus,
    postCapturingStoppedStatus
  }
}
