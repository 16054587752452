import React from 'react'

import { Filters } from '../../Filters/Filters'
import { ServiceMap } from '../../modals/ServiceMapModal/ServiceMapModal'
import { TrafficStatsFooter } from '../TrafficStatsFooter/TrafficStatsFooter'

import queryBuildAtom from '../../../recoil/queryBuild'
import { useSetRecoilState } from 'recoil'

import styles from './ServiceMapView.module.sass'

interface ServiceMapViewProps {
  reopenConnection?: () => void
}

export const ServiceMapView: React.FC<ServiceMapViewProps> = ({ reopenConnection }) => {
  const setQueryBuild = useSetRecoilState(queryBuildAtom)

  return (
    <div className={styles.ServiceMapViewContainer}>
      <Filters
        reopenConnection={reopenConnection}
        onQueryChange={(q) => {
          setQueryBuild(q?.trim())
        }}
        hideEntryDetailsBtn
      />
      <ServiceMap />
      <TrafficStatsFooter />
    </div>
  )
}
