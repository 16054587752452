import React, { useEffect, useState } from 'react'
import { Box, IconButton, Typography, Tooltip } from '@mui/material'

import styles from './AutoAppliedFilter.module.sass'
import variables from '../../../variables.module.scss'

import CodeEditor from '@uiw/react-textarea-code-editor'
import EditRoundedIcon from '@mui/icons-material/EditRounded';

interface AppliedSideFilterProps {
  name: string
  icon: JSX.Element
  filter: string
  editable?: boolean
  authorized?: boolean
  onEdit?: () => void
}

export const AutoAppliedFilter: React.FC<AppliedSideFilterProps> = ({
  name,
  icon,
  filter,
  editable = false,
  authorized = true,
  onEdit
}) => {
  const [copied, setCopied] = useState(false)
  const [filterHovered, setFilterHovered] = useState(false)
  const [valueHovered, setValueHovered] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout = null

    if (copied) {
      timeout = setTimeout(() => setCopied(false), 2000)
    }

    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Box
      display='flex'
      alignItems='center'
      gap='5px'
      className={styles.AutoAppliedFilter}
      onMouseEnter={() => setFilterHovered(true)}
      onMouseLeave={() => setFilterHovered(false)}
    >
      <Box display='flex' alignItems='center' gap='5px' flex='0.4'>
        {icon}
        <Typography
          variant='body2'
          fontSize={14}
          fontFamily={variables.textFontFamily}
          color={variables.fontColor}
          fontWeight={600}
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
        >
          {name}
        </Typography>
      </Box>
      <Box
        boxSizing='border-box'
        position='relative'
        flex='0.6'
        minWidth='280px'
      >
        <Tooltip
          title={copied ? 'Copied!' : 'Click to copy'}
          placement='top'
          arrow
        >
          <Box
            boxSizing='border-box'
            position='absolute'
            top='0'
            bottom='0'
            left='0'
            right='0'
            width='100%'
            height='100%'
            zIndex={1}
            onMouseEnter={() => setValueHovered(true)}
            onMouseLeave={() => setValueHovered(false)}
            onClick={() => {
              navigator.clipboard.writeText(filter).then(
                () => setCopied(true)
              )
            }}
            sx={{ cursor: 'pointer' }}
          ></Box>
        </Tooltip>
        <Box display='flex' alignItems='stretch' gap='5px'>
          <CodeEditor
            disabled
            language='py'
            value={filter}
            padding={4}
            data-color-mode='light'
            style={{
              transition: 'background-color 0.3s',
              flex: 1,
              border: `1px solid ${variables.dataBackgroundColor}`,
              borderRadius: '4px',
              fontSize: 12,
              backgroundColor: `${valueHovered ? variables.lightBlueColor : variables.mainBackgroundColor}`,
              fontFamily:
                'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
            }}
          />
          {editable && <Tooltip title={authorized ? 'Click to edit' : 'You are unauthorized to edit this filter'} placement='top' arrow>
            <IconButton color='primary' size='small' sx={{
              borderRadius: '4px',
              backgroundColor: filterHovered ? variables.blueColor : null,
              zIndex: 2,
              '&:hover': {
                backgroundColor: variables.blueColor
              }
            }} onClick={() => authorized && onEdit()} onMouseEnter={() => setFilterHovered(true)}>
              <EditRoundedIcon htmlColor={filterHovered ? variables.mainBackgroundColor : null} sx={{ fontSize: '12px' }} />
            </IconButton>
          </Tooltip>}
        </Box>
      </Box>
    </Box>
  )
}
