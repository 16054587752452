import React, { useCallback } from 'react'
import styles from './EntriesList.module.sass';
import down from "./assets/downImg.svg";
import { useRecoilValue } from "recoil";
import entriesAtom from "../../recoil/entries";
import { TrafficStatsFooter } from "../TrafficViewer/TrafficStatsFooter/TrafficStatsFooter";
import { TrafficEntry } from "../TrafficEntry/TrafficEntry";
import { Entry } from '../EntryListItem/Entry'
import { VirtualizedList, VirtualizedListHandle } from '../UI/VirtualizedList/VirtualizedList'

const TRAFFIC_ENTRY_HEIGHT = 62

interface EntriesListProps {
  listEntryREF: React.LegacyRef<HTMLDivElement>;
  onSnapBrokenEvent: () => void;
  isSnappedToBottom: boolean;
  setIsSnappedToBottom: (state: boolean) => void;
  scrollableRef: React.MutableRefObject<VirtualizedListHandle>;
}

export const EntriesList: React.FC<EntriesListProps> = ({
  listEntryREF,
  onSnapBrokenEvent,
  isSnappedToBottom,
  setIsSnappedToBottom,
  scrollableRef,
}) => {
  const entries = useRecoilValue(entriesAtom);

  const renderItem = useCallback((item: Entry, index: number, key, style) => {
    return (
      <TrafficEntry
        key={key}
        entry={item}
        duplicateOrigin={
          item.duplicate !== '' ? entries.get(item.duplicate) : null
        }
        style={style}
      />
    );
  }, []);

  return <React.Fragment>
    <div className={styles.list}>
      <div id="list" ref={listEntryREF} className={styles.list}>
        <VirtualizedList
          ref={scrollableRef}
          itemHeight={TRAFFIC_ENTRY_HEIGHT}
          renderItem={renderItem}
          items={[...entries.values()]}
          isSnappedToBottom={isSnappedToBottom}
          setIsSnappedToBottom={setIsSnappedToBottom}
          onSnapBroken={onSnapBrokenEvent}
        />
        <button type="button"
          title="Snap to bottom"
          className={`${styles.btnLive} ${isSnappedToBottom ? styles.hideButton : styles.showButton}`}
          onClick={() => {
            scrollableRef.current.jumpToBottom();
            setIsSnappedToBottom(true);
          }}>
          <img alt="down" src={down} />
        </button>
      </div>
      <TrafficStatsFooter />
    </div>
  </React.Fragment>;
};
