import React from "react";
import styles from "./Misc.module.sass"
import Button from '@mui/material/Button';
import { LinkDocs } from "../../../consts";
import { useSetRecoilState } from "recoil";
import scriptingModalOpenAtom from "../../../recoil/scriptingModalOpen/atom";
import ScriptingIcon from "./ScriptingIcon";
import DocsIcon from "./DocsIcon";
import { Link } from "@mui/material";
import { checkScriptingDisabled } from '../../../types/global'

interface GenericProps {
  className?: string,
}

export const DocsLink: React.FC<GenericProps> = () => {
  return <Link title="Documentation" className={styles.DocsLink} href={LinkDocs} target="_blank" rel="noopener">
    <DocsIcon></DocsIcon>
    <span>Docs</span>
  </Link>
}

export const ScriptingButton: React.FC<GenericProps> = () => {
  const setScriptingModalOpen = useSetRecoilState(scriptingModalOpenAtom);

  const handleScriptingModal = () => {
    setScriptingModalOpen(true);
  }

  return <Button
    startIcon={<ScriptingIcon />}
    size="large"
    title="Scripting"
    variant="contained"
    className={styles.Button}
    onClick={handleScriptingModal}
    disabled={checkScriptingDisabled()}
    style={{ textTransform: 'unset', height: '27px', lineHeight: 1.1 }}>
    Scripting
  </Button>
}
