import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import variables from '../../../variables.module.scss'

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} PopperProps={{style:{zIndex:1200}}} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '12px',
    backgroundColor: variables.mainBackgroundColor,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${variables.lightBlueColor}`,
    boxShadow: variables.lightSpreadShadow
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: variables.lightBlueColor,
  },
}));
