import React, { useCallback, useEffect, useRef, useState } from "react";
import TrafficViewerStyles from "./TrafficViewer.module.sass";
import playIcon from "./assets/run.svg";
import pauseIcon from "./assets/pause.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import reopenConnectionSignalAtom from "../../recoil/reopenConnectionSignal";

import focusedItemAtom from "../../recoil/focusedItem";
import focusedStreamAtom from "../../recoil/focusedStream";
import focusedRecordAtom from "../../recoil/focusedRecord";
import { StatusBar, Target } from "../UI/StatusBar/StatusBar";
import queryAtom from "../../recoil/query";
import { toast } from "react-toastify";
import { HubWsUrl } from "../../consts"
import { Entry } from "../EntryListItem/Entry";
import { useIdleTimer } from "react-idle-timer"

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import entriesAtom from "../../recoil/entries";
import { ApiCallsView } from "./ApiCallsView/ApiCallsView";
import { ServiceMapView } from "./ServiceMapView/ServiceMapView";
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from "../../recoil/trafficPlayback/atom";
import fullscreenViewAtom from "../../recoil/fullscreenView/atom";
import { Box } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import { getRefreshToken, getSessionToken } from "@descope/react-sdk";
import { useAuth } from "../UI/Auth/Auth";
import { appAuthEnabled, appOidcAuthTypeEnabled, appStopTrafficCapturingDisabled } from '../../types/global'
import { LoggedInAs } from "../UI/LoggedInAs/LoggedInAs";
import focusedEntryAtom from '../../recoil/focusedEntry'
import packetCapturingStoppedAtom from '../../recoil/packetCapturingStopped'
import streamingHealthAtom from '../../recoil/streamingHealth'
import { Utils } from '../../helpers/Utils'
import { useStreamingHealth } from '../../hooks/useStreamingHealth'
import { heapCacheObject, resetHeapCache } from '../../helpers/cacher/cacher'
import cacheableEntryFields from '../TrafficEntry/cacheableEntryFields'
import targetedPodsAtom from '../../recoil/targetedPods'
import { DisableTrafficCapturingButton } from './DisableTrafficCapturingButton/DisableTrafficCapturingButton'

export const TrafficViewer: React.FC = () => {
  const reopenConnectionSignal = useRecoilValue(reopenConnectionSignalAtom);
  const [entries, setEntries] = useRecoilState(entriesAtom);
  const setFocusedEntry = useSetRecoilState(focusedEntryAtom);
  const setFocusedItem = useSetRecoilState(focusedItemAtom);
  const setFocusedStream = useSetRecoilState(focusedStreamAtom);
  const setFocusedRecord = useSetRecoilState(focusedRecordAtom);
  const query = useRecoilValue(queryAtom);
  const fullscreenView = useRecoilValue(fullscreenViewAtom);
  const [isSnappedToBottom, setIsSnappedToBottom] = useState(true);
  const [wsReadyState, setWsReadyState] = useState(0);

  const targets = useRecoilValue<Target[]>(targetedPodsAtom)

  const scrollableRef = useRef(null);
  const ws = useRef(null);
  const queryRef = useRef(null);
  queryRef.current = query;

  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const streamingHealth = useRecoilValue(streamingHealthAtom)

  const { reportStreamingHealth } = useStreamingHealth()

  useIdleTimer({
    timeout: 5 * 60 * 1000,
    onIdle: () => {
      if (ws?.current?.readyState === WebSocket.OPEN) {
        closeWebSocket(4001);
        toast.warning("Connection is closed because of inactivity!", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    },
  });

  const { shouldAuthenticate } = useAuth()

  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)

  useEffect(() => {
    if (!appStopTrafficCapturingDisabled() && capturingStopped && wsReadyState === WebSocket.OPEN) {
      closeWebSocket(4001)
    }
  }, [wsReadyState, capturingStopped])

  useEffect(() => {
    const capturingStoppedOrNotReady = !appStopTrafficCapturingDisabled() && (capturingStopped === null || capturingStopped)

    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK || capturingStoppedOrNotReady) {
      closeWebSocket(4001)
      return
    }

    let init = false;
    if (!init) {
      if (shouldAuthenticate) {
        return
      }

      openWebSocket();
    }
    return () => { init = true; }
  }, [trafficPlayback, shouldAuthenticate, capturingStopped]);

  const closeWebSocket = useCallback((code: number) => {
    if (ws.current) {
      ws.current.close(code);
      reportStreamingHealth(null)
    }
  }, [ws]);

  const sendQueryWhenWsOpen = () => {
    setTimeout(() => {
      if (ws?.current?.readyState === WebSocket.OPEN) {
        ws.current.send(queryRef.current);
      } else {
        sendQueryWhenWsOpen();
      }
    }, 500);
  };

  const listEntry = useRef(null);
  const openWebSocket = (clearEntries = true) => {
    if (clearEntries) {
      setFocusedEntry(null)
      setFocusedItem(null);
      setEntries(new Map<string, Entry>());
      resetHeapCache()
    }

    let timer;

    try {
      const wsUrl = new URL(HubWsUrl);

      if (appAuthEnabled() && appOidcAuthTypeEnabled()) {
        wsUrl.searchParams.set('session-token', getSessionToken());
        wsUrl.searchParams.set('refresh-token', getRefreshToken());
      }

      ws.current = new WebSocket(wsUrl.toString());
      sendQueryWhenWsOpen();

      ws.current.onopen = () => {
        setWsReadyState(ws?.current?.readyState);

        reportStreamingHealth({
          state: true,
          metadata: Utils.summarizeWebSocketObject(ws?.current)
        })

        // toast.success("Connected to Hub.", {
        //   theme: "colored",
        //   autoClose: 1000,
        // });
      }

      ws.current.onclose = (e) => {
        clearTimeout(timer);

        setWsReadyState(ws?.current?.readyState);
        let delay = 3000;
        // let msg = "Trying to reconnect...";

        // 4001 is a custom code, meaning don't try to reconnect.
        switch (e.code) {
        case 1000:
          delay = 100;
          // msg = "Connecting with the new filter..."
          break;
        case 1006:
          if (streamingHealth === null || streamingHealth.state) {
            reportStreamingHealth({
              state: false,
              metadata: Utils.summarizeWebSocketCloseEvent(e)
            })
          }

          // toast.warning("Workers are down!", {
          //   theme: "colored",
          //   autoClose: 1000,
          // });
          break;
        case 4001:
          return;
        default:
          break;
        }

        // toast.info(msg, {
        //   theme: "colored",
        //   autoClose: 1000,
        // });

        setTimeout(() => {
          openWebSocket(e.code === 1000);
        }, delay);
      }

      ws.current.onerror = (err) => {
        clearTimeout(timer);

        console.error("WebSocket error:", err);
        if (err)
          toast.error(err, {
            theme: "colored",
            autoClose: 1000,
          });

        if (ws?.current?.readyState === WebSocket.OPEN) {
          ws.current.close();
        }
      }
    } catch (e) {
      reportStreamingHealth({
        state: false,
        metadata: e
      })

      console.error(e);
    }
  };

  const toggleConnection = useCallback(async () => {
    if (!appStopTrafficCapturingDisabled() && capturingStopped) {
      return
    }

    if (ws?.current?.readyState === WebSocket.OPEN) {
      closeWebSocket(4001);
    } else {
      openWebSocket(false);
    }
    if (scrollableRef?.current) {
      scrollableRef.current.jumpToBottom();
    }
    setIsSnappedToBottom(true);
  }, [scrollableRef, setIsSnappedToBottom, closeWebSocket, capturingStopped]);

  const reopenConnection = useCallback(async () => {
    closeWebSocket(1000);
    if (scrollableRef?.current) {
      scrollableRef.current.jumpToBottom();
    }
    setIsSnappedToBottom(true);
  }, [scrollableRef, setIsSnappedToBottom, closeWebSocket]);

  useEffect(() => {
    const capturingEnabledAndStopped = !appStopTrafficCapturingDisabled() && capturingStopped
    if (shouldAuthenticate || capturingEnabledAndStopped) {
      return
    }

    reopenConnection()
  }, [reopenConnectionSignal, shouldAuthenticate, capturingStopped])

  useEffect(() => {
    return () => {
      if (ws?.current?.readyState === WebSocket.OPEN) {
        ws.current.close();
      }

      resetHeapCache()
    };
  }, []);

  const getConnectionIndicator = () => {
    switch (wsReadyState) {
    case WebSocket.OPEN:
      return <div
        className={`${TrafficViewerStyles.indicatorContainer} ${TrafficViewerStyles.greenIndicatorContainer}`}>
        <div className={`${TrafficViewerStyles.indicator} ${TrafficViewerStyles.greenIndicator}`} />
      </div>
    default:
      return <div
        className={`${TrafficViewerStyles.indicatorContainer} ${TrafficViewerStyles.redIndicatorContainer}`}>
        <div className={`${TrafficViewerStyles.indicator} ${TrafficViewerStyles.redIndicator}`} />
      </div>
    }
  }

  const getConnectionTitle = (offline: boolean) => {
    switch (wsReadyState) {
    case WebSocket.OPEN:
      if (offline)
        return "offline mode"
      else
        return "streaming live traffic"
    default:
      if (offline)
        return "paused";
      else
        return "streaming paused";
    }
  }

  const onSnapBrokenEvent = () => {
    setIsSnappedToBottom(false);
  }

  if (ws.current && !ws.current.onmessage) {
    ws.current.onmessage = (e) => {
      if (!e?.data) return;
      const entry: Entry = heapCacheObject(JSON.parse(e.data), cacheableEntryFields);

      // TODO: Display the items with `event` field non-null in a new tab named `EVENT` instead of `API CALLS`
      if (entry.event) return;

      entry.key = `${entry.id}-${uuidv4()}`;

      if (entries.size === 0) {
        setFocusedEntry(entry)
        setFocusedItem(entry.id);
        setFocusedStream(entry.stream);
        setFocusedRecord(entry.record);
      }

      setEntries((prevEntries) => {
        prevEntries.set(entry.id, entry)
        return prevEntries
      })
    }
  }

  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const composeTabClassNames = (index) => {
    return `${TrafficViewerStyles.TrafficPageNavTab} ${currentTab === index ? TrafficViewerStyles.TrafficPageNavTabSelected : ''}`
  }

  return (
    <div className={TrafficViewerStyles.TrafficPage} style={{ height: fullscreenView ? '100%' : 'calc(100% - 70px)' }}>
      {!fullscreenView && <Box>
        <StatusBar />
        <div className={TrafficViewerStyles.TrafficPageHeader}>
          <div className={TrafficViewerStyles.TrafficPageStreamStatus}>
            <img id="pause-icon"
              className={TrafficViewerStyles.playPauseIcon}
              style={{ visibility: wsReadyState === WebSocket.OPEN ? "visible" : "hidden" }}
              alt="pause"
              src={pauseIcon}
              onClick={toggleConnection} />
            <img id="play-icon"
              className={TrafficViewerStyles.playPauseIcon}
              style={{ position: "absolute", visibility: wsReadyState === WebSocket.OPEN ? "hidden" : "visible" }}
              alt="play"
              src={playIcon}
              onClick={toggleConnection} />
            <div className={TrafficViewerStyles.connectionText}>
              {getConnectionTitle(targets.length === 0)}
              {getConnectionIndicator()}
            </div>
          </div>
          <Box boxSizing='border-box' position='absolute' left='250px'>
            <DisableTrafficCapturingButton mode='link' size='small' />
          </Box>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            aria-label="Tabs navigation"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
          >
            <Tab label="API Calls"
              className={composeTabClassNames(0)}
              hidden={currentTab !== 0}
            />
            <Tab
              label="Service Map"
              className={composeTabClassNames(1)}
              hidden={currentTab !== 1}
            />
          </Tabs>
          {appAuthEnabled() && <Box position='absolute' top='50%' right='24px' sx={{ transform: 'translateY(-50%)' }}><LoggedInAs /></Box>}
        </div>
      </Box>}
      {currentTab === 0 && <ApiCallsView
        listEntryREF={listEntry}
        reopenConnection={reopenConnection}
        onSnapBrokenEvent={onSnapBrokenEvent}
        isSnappedToBottom={isSnappedToBottom}
        setIsSnappedToBottom={setIsSnappedToBottom}
        scrollableRef={scrollableRef} />}
      {currentTab === 1 && <ServiceMapView reopenConnection={reopenConnection} />}
    </div>
  );
};
